import {mdiCheckCircle, mdiCircle, mdiCloseCircle} from '@mdi/js'
import Icon from '@mdi/react'

export const getIconState = (state?: string) => {
  if (state?.toLowerCase() === 'erogato') {
    return <Icon color='#389E0D' path={mdiCheckCircle} size={1} style={{marginRight: '8px'}} />
  } else if (state?.toLowerCase() === 'non erogato') {
    return <Icon color='#CF1322' path={mdiCloseCircle} size={1} style={{marginRight: '8px'}} />
  }
  return <Icon color='#FFC069' path={mdiCircle} size={1} style={{marginRight: '8px'}} />
}
