import {useEffect, useState} from 'react'
import apiClient from 'src/services/apiClient'
import {SecondStepStyled} from './SecondStepStyled'
import {Form, Input, Radio, Select} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import CardCustom from 'src/components/elements/CardCustom/CardCustom'
import {Snackbar, Typography} from '@mui/material'
import {FormattedMessage} from 'react-intl'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import {STATUS} from 'src/constants/constants'
import Header from 'src/components/templates/Header/Header'
import PrimaryButton from 'src/components/elements/Buttons/PrimaryButton/PrimaryButton'
import SecondaryButton from 'src/components/elements/Buttons/SecondaryButton/SecondaryButton'
import {CONSENTS} from '../../selfRegistrationConstants'
import TimeslotsSelector from 'src/components/elements/TimeslotsSelector/TimeslotsSelector'
import {TimeslotProps} from 'src/types/types'

const POSTAL_CODE_REGEX = /^[0-9]*$/
const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()]).{8,}$/

const consentOptions = [
  {value: 'CONSENT', label: 'Esprimo il consenso'},
  {value: 'NOT_CONSENT', label: 'Nego il consenso'}
]

type Option = {
  value: string,
  label: string,
}

type SecondStepProps = {
    setStepPage: any,
    config: any,
    email: any,
    registrationCode: any
}

const SecondStep: React.FC<SecondStepProps> = ({config, setStepPage, email, registrationCode}) => {
  const [status, setStatus] = useState<any>(undefined)
  const [statusRegistration, setStatusRegistration] = useState<any>(undefined)
  const [regions, setRegions] = useState<Option[]>([])
  const [provinces, setProvinces] = useState<Option[]>([])
  const [cities, setCities] = useState<Option[]>([])
  const [regionQuery, setRegionQuery] = useState<string>('')
  const [provinceQuery, setProvinceQuery] = useState<{search?: string, region?: string}>({})
  const [cityQuery, setCityQuery] = useState<{search?: string, province?: string}>({})
  // const [isUsernameValid, setIsUsernameValid] = useState<boolean>(true)
  const [step, setStep] = useState<number>(0)
  const [formValues, setFormValues] = useState<any>({})
  const [timeSlotValues, setTimeSlotValues] = useState<TimeslotProps[]>([])
  const [timeSlotValidation, setTimeSlotValidation] = useState<boolean>(true)

  const [form] = useForm()

  async function getRegions () {
    const regionsData = await apiClient.getRegions(regionQuery, setStatus)
    setRegions(regionsData)
  }

  async function getProvinces () {
    form.setFieldValue('province', undefined)
    form.setFieldValue('city', undefined)
    const provincesData = await apiClient.getProvinces(provinceQuery, setStatus)
    setProvinces(provincesData)
  }

  async function getCities () {
    form.setFieldValue('city', undefined)
    const citiesData = await apiClient.getCities(cityQuery, setStatus)
    setCities(citiesData)
  }

  // async function checkUsername (username:string) {
  //   const validUsername = await apiClient.checkUsername(username, setStatus)
  //   setIsUsernameValid(validUsername)
  // }

  useEffect(() => {
    form.setFieldValue('country', 'Italia')
    form.setFieldValue('email', email)
    getRegions()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getRegions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionQuery])

  useEffect(() => {
    getProvinces()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provinceQuery])

  useEffect(() => {
    getCities()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityQuery])

  const onFinish = async (values: Record<string, any>) => {
    delete formValues.confirmPassword
    delete values.consentPassword

    const privacyConsents: string[] = []
    CONSENTS.forEach((checkbox: any) => {
      if (values[checkbox.value] === 'CONSENT') {
        privacyConsents.push(checkbox.value)
      }
      delete values[checkbox.value]
    })

    const body = {
      ...formValues,
      birthDate: values.birthDate?.toISOString(),
      privacyConsents,
      preferredTimeSlots: timeSlotValues,
      token: registrationCode
    }

    await apiClient.postSelfRegistration(body, setStatusRegistration)
  }

  useEffect(() => {
    if (statusRegistration === STATUS.FULFILLED) {
      setStepPage(2)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusRegistration])

  const renderConsentCheckbox = () => {
    return CONSENTS.map((checkbox:any) => (
      <Form.Item
        className='consent'
        key={checkbox.value}
        label={checkbox.label}
        name={checkbox.value}
        rules={[
          {required: true, message: 'Questo campo è obbligatorio'},
          () => ({
            validator (_, value) {
              if ((checkbox.required && value === 'CONSENT') || !checkbox.required) {
                return Promise.resolve()
              }
              return Promise.reject(new Error('Questo consenso è obbligatorio'))
            }
          })
        ]}
      >
        <Radio.Group options={consentOptions} />
      </Form.Item>
    ))
  }

  return (
    config ?
      <SecondStepStyled>
        <Header showMenu={false} />
        {
          <div className='card-container'>
            {step === 0 &&
              <>
                <div style={{display: 'flex', flexDirection: 'column', fontSize: '26px', fontWeight: 600, textAlign: 'center'}}>
                  <b>{'Benvenuto ✍🏻!'}</b>
                  <b>{'Prima di accedere al tuo portale,'}</b>
                  <b>{'ti chiediamo un paio di dati:'}</b>
                </div>
                <CardCustom title={<Typography fontWeight={700} variant='body2'><FormattedMessage id='mainData' /></Typography>}>
                  <Form
                    form={form}
                    id='patient-digital-enrollment-form'
                    layout='vertical'
                    onFinish={onFinish}
                  >
                    <Form.Item
                      className='firstName'
                      label='Nome'
                      name='firstName'
                      rules={[
                        {required: true, message: 'Questo campo è obbligatorio'}
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      className='lastName'
                      label='Cognome'
                      name='lastName'
                      rules={[
                        {required: true, message: 'Questo campo è obbligatorio'}
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      className='password'
                      label='Password'
                      name='password'
                      rules={[
                        {required: true, message: 'Questo campo è obbligatorio'},
                        {pattern: PASSWORD_REGEX, message: 'Password troppo debole, assicurarsi che sia almeno da 8 caratteri, che abbia almeno una lettere maiuscola e una minuscola, che contenga un numero e un carattere speciale (tra !@#$%^&*())'}
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                      className='confirmPassword'
                      label='Conferma password'
                      name='confirmPassword'
                      rules={[
                        {required: true, message: 'Questo campo è obbligatorio'},
                        ({getFieldValue}) => ({
                          validator (_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve()
                            }
                            return Promise.reject(new Error('Le due password non corrispondono!'))
                          }
                        })
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                      className='fiscalCode'
                      label='Codice fiscale'
                      name='fiscalCode'
                      rules={[{required: true, message: 'Questo campo è obbligatorio'}]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      className='cellPhoneNumber'
                      label='Numero cellulare'
                      name='cellPhoneNumber'
                      rules={[{required: true, message: 'Questo campo è obbligatorio'}]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      className='email'
                      label='Email'
                      name='email'
                    >
                      <Input disabled />
                    </Form.Item>
                  </Form>
                </CardCustom>
                <CardCustom title={<Typography fontWeight={700} variant='body2'><FormattedMessage id='address' /></Typography>}>
                  <Form
                    form={form}
                    id='patient-digital-enrollment-form'
                    layout='vertical'
                    onFinish={onFinish}
                  >
                    <Form.Item
                      className='state'
                      label='Stato'
                      name='country'
                      rules={[{required: true, message: 'Questo campo è obbligatorio'}]}
                    >
                      <Select
                        disabled
                        getPopupContainer={(trigger) => trigger}
                      />
                    </Form.Item>
                    <Form.Item
                      className='region'
                      label='Regione'
                      name='region'
                      rules={[{required: true, message: 'Questo campo è obbligatorio'}]}
                    >
                      <Select
                        getPopupContainer={(trigger) => trigger}
                        onChange={(value) => setProvinceQuery({...provinceQuery, region: value})}
                        onSearch={(value) => setRegionQuery(value)}
                        options={regions}
                        showSearch
                      />
                    </Form.Item>
                    <Form.Item
                      className='province'
                      label='Provincia'
                      name='province'
                      rules={[{required: true, message: 'Questo campo è obbligatorio'}]}
                    >
                      <Select
                        getPopupContainer={(trigger) => trigger}
                        onChange={(value) => setCityQuery({...cityQuery, province: value})}
                        onSearch={(value) => setProvinceQuery({...provinceQuery, search: value})}
                        options={provinces}
                        showSearch
                      />
                    </Form.Item>
                    <Form.Item
                      className='city'
                      label='Città'
                      name='city'
                      rules={[{required: true, message: 'Questo campo è obbligatorio'}]}
                    >
                      <Select
                        getPopupContainer={(trigger) => trigger}
                        onSearch={(value) => setCityQuery({...cityQuery, search: value})}
                        options={cities}
                        showSearch
                      />
                    </Form.Item>
                    <Form.Item
                      className='address'
                      label='Indirizzo'
                      name='address'
                      rules={[{required: true, message: 'Questo campo è obbligatorio'}]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      className='streetNumber'
                      label='Civico'
                      name='streetNumber'
                      rules={[{required: true, message: 'Questo campo è obbligatorio'}]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      className='postalCode'
                      label='Codice Postale'
                      name='postalCode'
                      rules={[
                        {required: true, message: 'Questo campo è obbligatorio'},
                        {
                          pattern: POSTAL_CODE_REGEX,
                          message: 'Formato codice postale non valido'
                        }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      className='locality'
                      label='Località'
                      name='locality'
                    >
                      <Input />
                    </Form.Item>

                  </Form>
                </CardCustom>
                <CardCustom title={<Typography fontWeight={700} variant='body2'>{'Fasce orarie preferite per il contatto telefonico'}</Typography>}>
                  {!timeSlotValidation &&
                    <Typography className='form-text error-message' variant='body2'>
                      {'Compila tutti i campi'}
                    </Typography>}
                  <TimeslotsSelector onChange={(value) => { setTimeSlotValues(value); setTimeSlotValidation(true) }} value={timeSlotValues} />
                </CardCustom>
                <div className='button-container'>
                  <div />
                  <PrimaryButton
                    className='submit-button'
                    onClick={() => {
                      const validity = timeSlotValues.every((timeslot: any) => timeslot.day && timeslot.from && timeslot.to)
                      setTimeSlotValidation(validity)
                      if (!validity) {
                        return
                      }
                      form.validateFields().then((values) => {
                        setFormValues(values)
                        setStep(step + 1)
                      })
                    }}
                    variant='contained'
                  >
                    {'Avanti'}
                  </PrimaryButton>
                </div>
              </>}
            {step === 1 &&
              <>
                <div className='img-container'>
                  <img alt='logoPSP' className='img-style' src={'/assets/images/logo_IA.png'} />
                </div>
                <b style={{padding: '16px', textAlign: 'center'}}>{"Termini e condizioni d'uso del Servizio Digitale Demo"}</b>
                {terminiCondizioni()}
                <div style={{marginBottom: '8px'}}>
                  <div className='cookie-card'>
                    <CardCustom title={<Typography fontWeight={700} variant='body2'><FormattedMessage id='cookiePolicy' /></Typography>}>
                      <p>{"Utilizziamo cookie tecnici per garantire il corretto funzionamento del sito. Cliccando su 'Accetto', acconsenti all'uso di tali cookie. Per ulteriori informazioni consulta il documento di "}
                        <a href={'https://ia-internal-portals-test.italiassistenza-rte7p02.gcp.mia-platform.eu/static-files/cookie-policy.pdf'} rel='noreferrer' style={{textDecoration: 'underline'}} target='_blank'>{'cookie policy.'}</a>
                      </p>
                    </CardCustom>
                  </div>
                </div>
                <CardCustom title={<Typography fontWeight={700} variant='body2'><FormattedMessage id='consensus' /></Typography>}>
                  <Form
                    form={form}
                    id='patient-digital-enrollment-form'
                    layout='vertical'
                    onFinish={onFinish}
                    style={{display: 'block'}}
                  >

                    <p><b>{'Informativa ai sensi dell’art. 13 Regolamento UE 679/2016'}</b></p>
                    {informativaPrivacy()}

                    {renderConsentCheckbox()}
                    <div style={{marginBottom: '16px'}}>{"Il Consenso espresso è in ogni momento revocabile, senza pregiudicare la liceità del trattamento basata sul consenso fornito prima della revoca, inviando una comunicazione all'indirizzo email: demo@italiassistenza.it"}</div>
                    <Form.Item
                      className='consentPassword'
                      label='Reinserisci la password per confermare i consensi'
                      name='consentPassword'
                      rules={[
                        {required: true, message: 'Questo campo è obbligatorio'},
                        ({getFieldValue}) => ({
                          validator (_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve()
                            }
                            return Promise.reject(new Error('Le password non corrisponde con quella precedenti!'))
                          }
                        })
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                  </Form>
                </CardCustom>
                <div className='button-container'>
                  <SecondaryButton
                    className='submit-button'
                    onClick={() => {
                      setStep(step - 1)
                      form.setFieldsValue(formValues)
                    }}
                    variant='contained'
                  >
                    {'Indietro'}
                  </SecondaryButton>
                  <PrimaryButton
                    className='submit-button'
                    onClick={() => form.submit()}
                    variant='contained'
                  >
                    {'Invia'}
                  </PrimaryButton>

                </div>
              </>}
          </div>
        }
        <Snackbar
          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
          onClose={() => { setStatus(undefined) }}
          open={statusRegistration === STATUS.REJECTED}
        >
          <div>
            <SnackbarAlertCustom onClose={() => { setStatusRegistration(undefined) }} severity='error' sx={{width: '100%'}}>
              <Typography variant='h5'>
                {'Si è verificato un errore'}
              </Typography>
            </SnackbarAlertCustom>
          </div>
        </Snackbar>
        <Snackbar
          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
          onClose={() => { setStatus(undefined) }}
          open={status === STATUS.REJECTED}
        >
          <div>
            <SnackbarAlertCustom onClose={() => { setStatus(undefined) }} severity='error' sx={{width: '100%'}}>
              <Typography variant='h5'>
                {'Si è verificato un errore'}
              </Typography>
            </SnackbarAlertCustom>
          </div>
        </Snackbar>
      </SecondStepStyled> :
      <></>
  )
}

const terminiCondizioni = () => (
  <div style={{display: 'flex', flexDirection: 'column', fontSize: 14}}>
    <b style={{textAlign: 'center'}}>{'Termini e condizioni d’uso della piattaforma digitale dedicata al Programma di “assistenza domiciliare di Generali Welion'}</b>

    <p>{'DEFINIZIONI'}</p>
    <p>
      {'Ai fini dei presenti termini e condizioni d’uso (di seguito “Contratto” o “Termini e condizioni d’uso”) si intende per:'}
    </p>
    <p>
      {'Programma di “assistenza domiciliare di Generali Welion”: il servizio è fornito da ItaliAssistenza S.p.A. e distribuito da Generali Welion S.c.a.r.l., società appartenente al Gruppo Generali, fornendo servizi di assistenza domiciliare integrata, anche in telemedicina.'}
    </p>
    <p>
      {'Portale: Portale Web con accesso personale dedicato ai dipendenti che hanno accesso ai servizi del Programma di assistenza domiciliare.'}
    </p>
    <p>
      {'Gestore: ItaliAssistenza S.p.a., società italiana con sede legale a Reggio Emilia in Viale Monte San Michele, 9.'}
    </p>
    <p>
      {'Utente, Dipendente: Utente del servizio che ha sottoscritto il Modulo.'}
    </p>
    <p>
      {'Beneficiario: Utente del servizio, appartenente al nucleo familiare del Dipendente.'}
    </p>
    <p>
      {'Operatore: operatore sanitario, socio-assistenziale o medico che ha aderito al servizio e che eroga prestazioni sanitarie al Dipendente o Beneficiario.'}
    </p>
    <p>
      <strong>{'assistenzadomiciliare@italiassistenza.it:'}</strong>{' '}
      {'indirizzo e-mail al quale l’Utente può scrivere per chiedere informazioni, segnalare anomalie e/o disservizi, effettuare le comunicazioni previste dai Termini e condizioni d’uso in merito all’utilizzo del Portale e alla partecipazione al servizio.'}
    </p>

    <p style={{textAlign: 'center'}}>{'ARTICOLO 1 - ACCETTAZIONE TERMINI E CONDIZIONI D’USO'}</p>
    <p>
      {'1.1 L’Utente dichiara di conoscere e di accettare, senza riserva alcuna, tutte le disposizioni e le clausole relative al Portale indicate nei Termini e condizioni d’uso.'}
    </p>
    <p>
      {'1.2 I Termini e condizioni d’uso del Portale possono essere modificati unilateralmente dal Gestore, in ogni momento, con contestuale comunicazione all’Utente a mezzo Portale. Il Servizio web non sarà usufruibile dall’Utente sino a quando l’Utente stesso non avrà accettato le modifiche. La mancata accettazione delle modifiche nei trenta giorni successivi alla loro comunicazione comporterà la risoluzione del Contratto ai sensi del successivo art. 8.'}
    </p>

    <p style={{textAlign: 'center'}}>{'ARTICOLO 2 - OGGETTO DEL SERVIZIO DIGITALE TRAMITE IL PORTALE'}</p>
    <p>
      {'2.1 I Termini e condizioni d’uso disciplinano le condizioni di utilizzo del Portale, inteso come Portale web personalizzato dedicato ai Dipendenti che beneficiano del welfare Generali Welion.'}
    </p>
    <p>
      {'2.2 Il Programma di assistenza domiciliare consente all’Utente di usufruire di una serie di servizi di assistenza domiciliare integrata, anche attraverso l’utilizzo di strumenti di telemedicina.'}
    </p>

    <p style={{textAlign: 'center'}}>{'ARTICOLO 3 - STRUTTURA E CONTENUTO DEL PORTALE WEB'}</p>
    <p>
      {'2.3 Il Programma di assistenza domiciliare è erogato mettendo a disposizione del Dipendente, nei limiti di cui ai presenti Termini e Condizioni d’uso, il Portale.'}
    </p>
    <p>{'2.4 Il Portale offre all’Utente le seguenti funzionalità (di seguito, anche, “Servizi”):'}</p>
    <ul>
      <li><b>{'Home Page: '}</b>{'Informazioni sul numero verde dedicato e di come accedere ai servizi previsti dal programma welfare.'}</li>
      <li><b>{'Agenda: '}</b>{'Agenda dell’Utente che consente di consultare i servizi già erogati e prenotati, in cui questi è direttamente coinvolto. L’Utente potrà selezionare la modalità di visualizzazione (giornaliera - settimanale - mensile - tabellare).'}</li>
      <li><b>{'Prestazioni: '}</b>{'Tale funzione consente all’Utente di visualizzare le attività effettuate e programmate a domicilio del Paziente e registrate dal Gestore.'}</li>
      <li><b>{'Area Personale (Profilo Utente): '}</b>{'Questa sezione consente di visualizzare i dati del Dipendente e del proprio nucleo familiare quali: indirizzo, numeri di telefono ed indirizzi e-mail. La sezione include uno spazio con la possibilità di modificare il numero di cellulare e le fasce orarie preferenziali per ricevere i contatti dalla Centrale Operativa.'}</li>
      <li><b>{'Messaggi e Notifiche: '}</b>{'Tale sezione consente al Paziente di visualizzare in ogni momento la lista nonché il contenuto delle notifiche relative alla ricezione di e-mail, sms e reminder a lui destinati e inerenti al servizio.'}</li>
      <li><b>{'Contattami, Welcome call: '}</b>{'Tale funzione consente all’Utente di inviare una richiesta per essere ricontattato dalla Centrale Operativa di ItaliAssistenza, per un benvenuto al servizio, orientamento sulla piattaforma ed informazioni sui servizi previsti nel programma welfare, o per richiedere assistenza sul programma.'}</li>
      <li><b>{'Informazioni sul servizio: '}</b>{'Questa sezione consente la fruizione di documenti utili all’Utente nella gestione dei servizi (a titolo esemplificativo ma non esaustivo, guida ai servizi inclusi nel pacchetto welfare, manuale dei servizi e sottoservizi, listino delle prestazioni acquistabili dal dipendente).'}</li>
      <li><b>{'Documenti: '}</b>{'Tale funzione consente all’Utente di condividere con il personale di ItaliAssistenza (caricare e scaricare) i documenti inerenti al servizio (a titolo esemplificativo ma non esaustivo: PAI). Sarà quindi possibile consultare i documenti caricati in ogni momento tramite Portale.'}</li>
      <li><b>{'Questionari: '}</b>{'Questionario di valutazione e gradimento del servizio (che l’utente potrà compilare in autonomia tramite il portale web oppure al telefono con l’ausilio della Centrale Operativa).'}</li>
    </ul>

    <p style={{textAlign: 'center'}}>{'ARTICOLO 4 - REGISTRAZIONE AL PORTALE WEB'}</p>
    <p>
      {'4.1 L’utilizzo del Portale è subordinato ad apposita registrazione ed alla:'}
    </p>
    <ul>
      <li>{'accettazione dei Termini e condizioni d’uso;'}</li>
      <li>{'presa visione dell’informativa sul trattamento dei dati personali (con facoltà di rilascio dei consensi ivi previsti).'}</li>
    </ul>
    <ul>
      <li>{'Il dipendente riceve dalla propria azienda il codice per accedere e registrarsi sulla landing page di Generali Welion;'}</li>
      <li>{'Il dipendente si registra sulla landing page Generali Welion, sulla quale avviene la raccolta delle anagrafiche e la presa visione dell’informativa sul trattamento dei dati personali di Generali Welion S.c.a.r.l.;'}</li>
      <li>{'Il dipendente che si è registrato sulla landing page Generali Welion riceve, tramite mail e rimando a thank you page, il link di accesso e il codice di attivazione sulla Piattaforma IA;'}</li>
      <li>{'Il dipendente inizia la registrazione sulla Piattaforma IA inserendo la propria e-mail e il codice fornito da Generali Welion;'}</li>
      <li>{'Il dipendente prosegue il flusso di registrazione inserendo i dati anagrafici e i consensi al trattamento dei dati sanitari sulla piattaforma di IA;'}</li>
      <li>{'il dipendente riceve una mail di “conferma” registrazione all’indirizzo fornito, e il click sul “conferma” lo abilita al primo accesso alla Piattaforma IA;'}</li>
      <li>{'Il dipendente (eventualmente l’assistito), accedono alla Piattaforma IA e usufruiscono delle funzionalità.'}</li>
    </ul>
    <p>{'Al termine di tale prima fase sarà possibile accedere al Portale, fermo restando:'}</p>
    <ul>
      <li>{'l’accettazione dei Termini e condizioni d’uso: spuntando la relativa check-box di accettazione;'}</li>
      <li>{'la presa visione dell’informativa sul trattamento dei dati personali (con facoltà di rilascio dei consensi ivi previsti).'}</li>
    </ul>
    <p>{'4.3 Il Gestore si riserva il diritto di sospendere/disabilitare un Account ai sensi del successivo art. 8.'}</p>

    <p style={{textAlign: 'center'}}>{'ARTICOLO 5 - RECESSO DAL CONTRATTO'}</p>
    <p>
      {'L’Utente potrà disabilitare il proprio Account sul Portale e, quindi, esercitare il diritto di recesso dal servizio in ogni momento uscendo dal programma welfare. Per far ciò l’Utente è invitato a comunicare la propria intenzione all’indirizzo e-mail'}<b>{'assistenzadomiciliare@italiassistenza.it'}</b>{'.'}
    </p>

    <p style={{textAlign: 'center'}}>{'ARTICOLO 6 - OBBLIGHI E RESPONSABILITÀ DELL’UTENTE'}</p>
    <p>{'6.1 L’Utente si impegna ad utilizzare il Portale con la diligenza del buon padre di famiglia.'}</p>
    <p>{'6.2 Durante l’adesione al servizio welfare e l’utilizzo del Portale, l\'Utente non potrà:'}</p>
    <ul>
      <li>
        {'creare opere che sfruttino il Portale o la loro immagine per finalità diverse da quelle per cui sono stati creati, distribuire e mostrare tali opere pubblicamente (su qualsivoglia supporto digitale, analogico o cartaceo) o in streaming nonché sfruttare il PSP e/o i contenuti del Servizio Digitale per finalità diverse da quelle per cui sono stati creati;'}
      </li>
      <li>{'de-compilare, replicare o modificare il Portale;'}</li>
      <li>{'creare un nuovo prodotto che si interfacci con il Portale;'}</li>
      <li>
        {'creare qualsiasi programma o elaborato al fine di prelevare, indicizzare, esaminare, o utilizzare altri metodi di estrazione dei dati dal Portale o di intralciare/ostacolare indebitamente la gestione e/o il funzionamento di qualunque aspetto del Portale;'}
      </li>
      <li>
        {'tentare di ottenere accesso non autorizzato o compromettere qualsiasi aspetto del Portale o dei sistemi o reti ad essi relativi;'}
      </li>
      <li>
        {'inviare, caricare o pubblicare qualunque contenuto diffamatorio, calunnioso, riprovevole, violento, osceno, pornografico, illegale, o altrimenti offensivo sul Portale;'}
      </li>
      <li>
        {'violare qualsiasi diritto legittimo, compresi a titolo non esaustivo i diritti di proprietà intellettuale;'}
      </li>
      <li>{'considerare il Portale quale dispositivo medico.'}</li>
    </ul>
    <p>{'6.3 L’Utente è responsabile per tutte le attività che sono poste in essere mediante l’utilizzo del proprio Account, oltre che della sicurezza e segretezza delle proprie credenziali.'}</p>
    <p>{'6.4 L’Utente si impegna, qualora cambi azienda rispetto a quella che ha attivato il servizio welfare, a non utilizzare più il Portale. '}</p>

    <p style={{textAlign: 'center'}}>{'ARTICOLO 7 - LIMITAZIONI ALLA RESPONSABILITÀ DEL GESTORE'}</p>
    <p>{'Il Gestore, le società ad esso collegate ed i rispettivi funzionari, amministratori, dipendenti, collaboratori hanno adottato ogni ragionevole precauzione nell\'offerta dei Servizi. Gli stessi, non rilasciano alcuna garanzia circa il contenuto del Portale e del software di base, compresi, a titolo esemplificativo ma non esaustivo, la sua accuratezza, affidabilità, completezza o tempestività e declinano espressamente, nella misura massima consentita dalla legge, tutte le garanzie tacite di idoneità ad un particolare uso e tutte le garanzie di proprietà e non violazione dei diritti di terzi, rispetto ai materiali accessibili tramite Portale.'}</p>
    <p>{'Nella misura massima consentita dalla legge applicabile, ItaliAssistenza e non sono responsabili per qualsiasi danno diretto e/o indiretto derivante dall’uso del Portale o di qualsiasi materiale disponibile in essi.'}</p>

    <p style={{textAlign: 'center'}}>{'ARTICOLO 8 - CLAUSOLA RISOLUTIVA ESPRESSA'}</p>
    <p>{'Il Gestore avrà la facoltà di risolvere il Contratto, ai sensi e per gli effetti dell’art. 1456 c.c., mediante semplice comunicazione scritta all’Utente, nel caso in cui questi non rispetti quanto stabilito nei Termini e condizioni d’uso e, nello specifico nel caso di:'}</p>
    <ul>
      <li>{'Violazione da parte dell’Utente degli obblighi di cui all’articolo 6 del Contratto;'}</li>
      <li>{'Mancata accettazione delle modifiche ai Termini e condizioni d’uso.'}</li>
    </ul>

    <p style={{textAlign: 'center'}}>{'ARTICOLO 9 - COMUNICAZIONI E MODIFICHE'}</p>
    <p>{'9.1 L’Utente consente che qualsiasi comunicazione relativa al Contratto, all’invio di documentazione inerente al servizio di welfare e/o alle modalità di organizzazione dello stesso sia effettuata dal Gestore a mezzo Portale.'}</p>
    <p>{'La modifica nel contratto di lavoro nonché il passaggio ad azienda differente rispetto a quella prevista dal servizio di welfare dovranno essere tempestivamente comunicate all’indirizzo e-mail'} <b>{'assistenzadomiciliare@italiassistenza.it'}</b></p>

    <p style={{textAlign: 'center'}}>{'ARTICOLO 10 - FORO COMPETENTE'}</p>
    <p>{'Per qualunque controversia che dovesse insorgere relativamente al Contratto e, in particolare, all’interpretazione ed alla esecuzione dello stesso sarà competente, in via esclusiva, il foro di Milano.'}</p>

    <p style={{textAlign: 'center'}}>{'ARTICOLO 11 - DISPOSIZIONI FINALI'}</p>
    <p>{'11.1 Qualora una delle clausole previste dai Termini e condizioni d’uso sia/diventi inefficace/inapplicabile, la validità delle restanti clausole non sarà pregiudicata. Ove una delle clausole previste all’interno dei presenti Termini e condizioni d’uso sia/diventi illegale, invalida, o inapplicabile per qualsivoglia motivo, il Gestore dovrà sostituire tale clausola, o parte della stessa, con una clausola legale, valida ed applicabile che si prefigga, per quanto possibile, un effetto simile a quello della disposizione disattesa.'}</p>
    <p>{'11.2 Per tutto quanto non espressamente qui richiamato si rimanda al Codice civile vigente e alle leggi applicabili in materia.'}</p>

    <p>{'Ai sensi degli artt. 1341 e 1342 cod. civ., l’Utente dichiara di aver compreso e accettato le seguenti clausole:'}</p>
    <p>{'art.1 (accettazione Termini e condizioni d’uso); art.4 (Registrazione al Servizio Digitale); art.5 (recesso); art.6 (obblighi e responsabilità dell’Utente); art.7 (limitazioni alla responsabilità del Gestore); art.10 (Foro competente).'}</p>
  </div>
)

const informativaPrivacy = () => (
  <div style={{display: 'flex', flexDirection: 'column', fontSize: 14}}>
    <p>
      {'Gentile Utente, avendo compreso le modalità di funzionamento del Programma di “assistenza domiciliare di Generali Welion” (il servizio è fornito da ItaliAssistenza S.p.A. e distribuito da Generali Welion S.c.a.r.l., società appartenente al Gruppo Generali), sottoscrivendo tale modulo decide di aderire ai servizi oggetto della presente Informativa. ItaliAssistenza S.p.A., in qualità di Titolare autonomo del trattamento dei dati, La informa circa le finalità e i mezzi con cui i Suoi dati saranno trattati nell’ambito del servizio.'}
    </p>

    <b>{'1. Dati personali trattati'}</b>
    <p>{'I dati personali che saranno raccolti e trattati nell’ambito del servizio sono:'}</p>
    <ul>
      <li>{'Dati anagrafici (nome, cognome, data e luogo di nascita);'}</li>
      <li>{'Dati di contatto (indirizzo domicilio, telefono, cellulare, e-mail);'}</li>
      <li>
        {'Dati relativi al suo stato di salute (necessari per giustificare la Sua partecipazione al servizio);'}
      </li>
      <li>
        {'Dati anagrafici e di salute relativi al Membro del nucleo familiare (beneficiario), per cui sta richiedendo il servizio.'}
      </li>
    </ul>
    <p>
      {'Il trattamento dei dati indicati è necessario per gestire correttamente la Sua adesione.'}
    </p>

    <b>{'2. Finalità del trattamento'}</b>
    <p>{'I Suoi dati personali saranno utilizzati esclusivamente per le seguenti finalità:'}</p>
    <ul>
      <li>
        {'Organizzazione e gestione delle attività per il cui dettaglio si rinvia alla sezione del sito “informazioni sul servizio”;'}
      </li>
      <li>
        {'Invio di comunicazioni di servizio e materiale informativo relativo al Programma di assistenza domiciliare tramite contatto telefonico, invio di sms o di e-mail;'}
      </li>
      <li>
        {'Somministrazione di questionari e/o esecuzione di indagini telefoniche, o via web, relative alla qualità dei servizi erogati.'}
      </li>
    </ul>
    <p>
      {'Inoltre, i Suoi dati potranno essere trattati per finalità statistiche o di divulgazione al solo scopo di valutare e/o dimostrare l’efficacia dei servizi erogati. Tale tipo di operazione prevede l’utilizzo dei Suoi dati in forma rigorosamente anonima.'}
    </p>

    <b>{'3. Modalità del trattamento'}</b>
    <p>
      {'Il trattamento dei Suoi dati personali è realizzato sia in modalità cartacea sia con l’ausilio di mezzi elettronici e/o informatici.'}
    </p>

    <b>{'4. Titolare del Trattamento e Responsabili del Trattamento'}</b>
    <p>
      {'Il Titolare autonomo del trattamento dei Suoi dati è ItaliAssistenza S.p.A., società con sede legale a Reggio Emilia ‑ Viale Monte San Michele, 9, 42121 e con sede operativa in Via Lillo del Duca, 10, 20091, Bresso (MI).'}
    </p>
    <p>
      {'La lista dei Responsabili del Trattamento incaricati da ItaliAssistenza S.p.A. è presente presso la sede di ItaliAssistenza stessa.'}
    </p>

    <b>{'5. Ambito di comunicazione dei dati personali'}</b>
    <p>
      {'La informiamo che il Titolare raccoglierà e gestirà i Suoi dati attraverso soggetti debitamente autorizzati a svolgere le attività relative ai servizi erogati (quali a titolo esemplificativo e non esaustivo infermieri, professionisti del settore sanitario e assistenziale che erogano sul territorio i servizi di assistenza socio-sanitaria a domicilio od anche attraverso l’utilizzo di strumenti di telemedicina) ai quali sono state fornite istruzioni da seguire al fine di garantire elevati livelli di riservatezza.'}
    </p>
    <p>
      {'I dati raccolti potranno essere oggetto di comunicazione ai soggetti la cui facoltà di accedere ai dati sia riconosciuta dalla legge.'}
    </p>

    <b>{'6. Trasferimento dei dati all’estero'}</b>
    <p>{'I Suoi dati sono conservati su server ubicati in Europa.'}</p>

    <b>{'7. Periodo di conservazione'}</b>
    <p>
      {'I Suoi dati saranno conservati per il tempo strettamente necessario all’espletamento del servizio e per gli scopi per i quali sono stati raccolti conformemente a quanto previsto dagli obblighi di legge e, comunque, per un periodo non superiore a 10 anni dalla chiusura del servizio.'}
    </p>

    <b>{'8. Base giuridica del Trattamento e natura del conferimento dei dati'}</b>
    <p>
      {'Tratteremo i Suoi dati personali sulla base del consenso espresso. Il Suo consenso al trattamento dei dati per le finalità indicate è necessario ai fini della partecipazione al servizio. Eventuali rifiuti comporteranno l’impossibilità di erogare i servizi.'}
    </p>

    <b>{'9. Diritti dell’interessato'}</b>
    <p>
      {'In relazione ai predetti trattamenti, Lei potrà esercitare i diritti che Le sono riconosciuti dalla normativa in materia di protezione dei dati (artt. da 15 a 22 GDPR) tra cui, ad esempio, il diritto di revocare il proprio consenso, il diritto di chiedere l\'integrazione, l\'aggiornamento, la rettificazione o (nei casi previsti) la cancellazione dei propri dati e il diritto di richiedere la limitazione del trattamento od opporsi allo stesso. Infine, qualsiasi Sua contestazione relativa ai trattamenti sopra descritti potrà essere oggetto di reclamo dinnanzi al Garante per la protezione dei dati personali, Piazza di Venezia, 11, 00186, Roma. Tali diritti possono farsi valere scrivendo al Titolare del Trattamento: ItaliAssistenza S.p.A., con sede legale in Viale Monte San Michele, 9, 42121, Reggio Emilia (RE), contattando il DPO incaricato all’indirizzo e-mail: dpo@italiassistenza.it . Ulteriori informazioni in ordine al trattamento e alla comunicazione di dati personali forniti direttamente o altrimenti acquisiti, potranno essere richieste al medesimo soggetto.'}
    </p>

    <b style={{textAlign: 'center'}}>{'CONSENSO AL TRATTAMENTO DEI DATI'}</b>
    <p>{'Io sottoscritto, le cui generalità sono sopra indicate, dichiaro:'}</p>
    <ul>
      <li>{'1. Di aver compreso le finalità dei servizi socio-assistenziali e sanitari e che l’adesione agli stessi prevede la loro attivazione descritta nella sezione del portale “informazioni sul servizio”;'}</li>
      <li>{'2. Di aver ricevuto, preso visione letto e compreso l’Informativa sulla Protezione dei Dati contenuta nel presente modulo;'}</li>
      <li>{'3. Di acconsentire espressamente al trattamento dei Dati per le finalità indicate all’articolo 2 lett. (a) e (b) dell’informativa, necessarie per poter beneficiare dei servizi di Cure Domiciliari.'}</li>
    </ul>
  </div>
)

export default SecondStep
