import {useEffect, useState} from 'react'
import {Snackbar, Typography} from '@mui/material'
import SnackbarAlertCustom from 'src/components/elements/SnackbarAlertCustom/SnackbarAlertCustom'
import TableCustom, {
  PaginationType,
  TableColumn
} from 'src/components/elements/TableCustom/TableCustom'
import LayoutContainer from 'src/components/layouts/LayoutContainer/LayoutContainer'
import {PATHS, STATUS} from 'src/constants/constants'
import apiClient from 'src/services/apiClient'
import {PerformancesStyled} from './PerformanceStyle'
import {getIconState} from 'src/utils/performances'

const staticColumns: TableColumn[] = [
  {
    id: 'date',
    type: 'date',
    label: 'Data',
    format: 'ddd DD MMMM YYYY'
  },
  {
    id: 'title',
    label: 'Prestazione'
  },
  {
    id: 'realBeneficiary',
    label: 'Beneficiario',
    render: (realBeneficiary) => <div>{realBeneficiary}</div>
  },
  {
    id: 'state',
    label: 'Stato',
    render: (state) => <div className='cell-icon-table'>{getIconState(state)} {state}</div>
  }
]

const Performances = () => {
  const [data, setData] = useState<Record<string, unknown>[]>([])
  const [count, setCount] = useState(0)
  const [status, setStatus] = useState<any>(undefined)
  const [statusCount, setStatusCount] = useState<string>()
  const [pagination, setPagination] = useState<PaginationType>({
    page: 0,
    rowsPerPage: 10,
    order: ''
  })

  const fetchData = async () => {
    const fetchedData = (await apiClient.getUserPerformances(setStatus, pagination)) ?? []
    setData(fetchedData)
    const fetchedDataCount =
      (await apiClient.getUserPerformancesCount(setStatusCount)) ?? []
    setCount(fetchedDataCount)
  }

  useEffect(() => {
    fetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination])

  return (
    <LayoutContainer
      breadcrumbs={[{label: 'Le tue prestazioni', href: PATHS.AGENDA}]}
    >
      <PerformancesStyled>
        <Snackbar
          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
          onClose={() => {
            setStatus(undefined)
            setStatusCount(undefined)
          }}
          open={status === STATUS.REJECTED || statusCount === STATUS.REJECTED}
        >
          <div>
            <SnackbarAlertCustom
              onClose={() => {
                setStatus(undefined)
                setStatusCount(undefined)
              }}
              severity='error'
              sx={{width: '100%'}}
            >
              <Typography variant='h5'>
                {"Errore nel recuperare le prestazioni dell'utente."}
              </Typography>
            </SnackbarAlertCustom>
          </div>
        </Snackbar>
        <TableCustom
          columns={staticColumns}
          onPaginationChange={setPagination}
          pagination={pagination}
          rows={data}
          totalElements={count}
        />
      </PerformancesStyled>
    </LayoutContainer>
  )
}

export default Performances
